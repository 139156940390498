import React, { createContext, useContext, useReducer } from "react";

const initialState = {
  isDrawerOpen: false,
};

const AppContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case "toggleDrawer":
      return {
        ...state,
        isDrawerOpen: action.isDrawerOpen,
      };

    default:
      return state;
  }
};

export const AppProvider = ({ children }) => {
  return (
    <AppContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  const [state, dispatch] = useContext(AppContext);
  return { state, dispatch };
};
