import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import colors from "./colors";

const breakpoints = createBreakpoints({
  xs: "576px",
  sm: "768px",
  md: "1024px",
  lg: "1240px",
  xl: "1680px",
});

const overrides = {
  colors,
  breakpoints,
};

export default extendTheme(overrides);
