import React from "react";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { AppProvider } from "./src/context/AppProvider";

export const wrapRootElement = ({ element }) => {
  return (
    <ChakraProvider CSSReset>
      <CSSReset />
      <AppProvider>{element}</AppProvider>
    </ChakraProvider>
  );
};
