const colors = {
  primary: {
    50: "#CAF6E3",
    100: "#CAF6E3",
    200: "#99EDD0",
    300: "#5EC9AD",
    400: "#319382",
    500: "#094B46",
    600: "#063F40",
    700: "#043036",
    800: "#02232B",
    900: "#011A24",
  },

  secondary: {
    50: "#FDFCF3",
    100: "#FDFCF3",
    200: "#FCF9E8",
    300: "#F8F2D9",
    400: "#F1E9CC",
    500: "#e8ddb9",
    600: "#C7B687",
    700: "#A7925D",
    800: "#866F3B",
    900: "#6F5623",
  },
};

export default colors;
